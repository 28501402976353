import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const AddressLink = ({ address, amount }) => (
  <div>
    <h3>{amount} BTC</h3>
    <a href={`https://www.blockonomics.co/#/search?q=${address}`} className="App-link" target="_blank" rel="noopener noreferrer">
      {address}
    </a>
  </div>
);

const Home = () => (
  <div className="App">
    <header className="App-header">
      <h1>TaintCrypto - Track Stolen Bitcoin</h1>
      <AddressLink address="bc1q9un7rfxe6jmaftwq09uwjttw3c9rv965u2jjju" amount="5.0366112" />
      <AddressLink address="bc1q0qaeknzx59nr4kh8p2u25j35wtd4grfase8m2v" amount="4.9762273" />
      <AddressLink address="1J4nWvPZJn5Cnyd4v8Hj6UsQCDmx2yTSZ5" amount="10" />
      <h2> Dutch Police report: PL1300-2024064012-2</h2>
    </header>
  </div>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
